import { Component, Input, OnInit } from "@angular/core";
import { ZendeskQAAPIService } from "@app/shared/api/zendesk-qa.api.service";
import { IState } from "@app/models/state/state.model";
import { Globals } from "@app/shared/globals/globals";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-information-sidebar-zendeskqa",
  templateUrl: "./information-sidebar-zendeskqa.component.html",
  styleUrls: ["./information-sidebar-zendeskqa.component.scss"],
})
export class InformationSidebarZendeskqaComponent implements OnInit {
  @Input() userIds: number[];

  iqsMonth: number;
  iqsTwoMonths: number;
  delta: unknown;
  state: IState;
  subjectUserId: number;

  constructor(
    private zendeskQAAPIService: ZendeskQAAPIService,
    private globals: Globals
  ) {
    this.userIds = [];
    this.subjectUserId = undefined;
    this.iqsMonth = 0;
    this.iqsTwoMonths = 0;
    this.delta = 0;
    this.state = {
      loading: true,
      error: false,
      errorMessage: "",
    };
  }

  ngOnInit(): void {
    this.getData();
  }

  error(): void {
    this.state.loading = false;
    this.state.error = true;
  }

  getData(): void {
    this.state.loading = true;

    // the first user tends to be the other user for the 1:1
    // otherwise, the first user is probably the current user and they are doing a self reflection
    this.subjectUserId = this.userIds[0] || 0;

    if (this.subjectUserId === 0) {
      this.error();
      return;
    }

    if (this.subjectUserId === this.globals.user.id) {
      console.log("[ZendeskQA] User is viewing their own data");
    }

    this.iqsMonth = 0;
    this.iqsTwoMonths = 0;

    forkJoin([
      this.zendeskQAAPIService.getIQSByUserId(this.subjectUserId, 1),
      this.zendeskQAAPIService.getIQSByUserId(this.subjectUserId, 2),
    ]).subscribe(([oneMonth, twoMonths]) => {
      this.iqsMonth = oneMonth;
      this.iqsTwoMonths = twoMonths;

      let delta = this.iqsMonth - this.iqsTwoMonths;

      if (isNaN(delta)) {
        this.delta = 0;
      }

      if (delta > 0) {
        this.delta = "+" + delta;
      } else {
        this.delta = delta;
      }
    });

    this.state.loading = false;
  }
}
