import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {MergeDevLinkTokenResponse} from '@models/integrations/mergedev/merge-dev-link-token.model';
import { Observable } from 'rxjs';
import { MergeDevLinkedAccount } from '@app/models/integrations/mergedev/merge-dev-linked-account.model';
import { MergeDevAccountToken } from '@app/models/integrations/mergedev/merge-dev-account-token.model';
import { MergeDevTicketInfoByUserIdResponse } from '@app/models/integrations/mergedev/ticketing/merge-dev-ticket-info-by-user-id.model';

@Injectable()
export class MergeDevAPIService {

  private readonly BASE_URL = 'api/merge-dev';

  constructor(
    private http: HttpClient
  ) { }

  getLinkToken(): Observable<MergeDevLinkTokenResponse> {
    const url = `${this.BASE_URL}/link-key`;
    return this.http.get<MergeDevLinkTokenResponse>(url);
  }

  createAccountTokenFromPublicToken(publicToken: string): Observable<MergeDevAccountToken> {
    const url = `${this.BASE_URL}/account-token`;
    return this.http.post<MergeDevAccountToken>(url, publicToken);
  }

  getAccountTokens(): Observable<MergeDevAccountToken[]> {
    const url = 'api/merge-dev/account-tokens';
    return this.http.get<MergeDevAccountToken[]>(url);
  }

  getLinkedAccounts(): Observable<MergeDevLinkedAccount[]> {
    const url = `${this.BASE_URL}/linked-accounts`;
    return this.http.get<MergeDevLinkedAccount[]>(url);
  }

  getTicketsByUser(): Observable<MergeDevTicketInfoByUserIdResponse[]> {
    const url = `${this.BASE_URL}/tickets/by-user`;
    return this.http.get<MergeDevTicketInfoByUserIdResponse[]>(url);
  }

}
